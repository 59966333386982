import axios from 'axios'

const setVisit = async (nPublicidadID=null) => {
    const {data} = await axios.get('https://geolocation-db.com/json/')
    const send = {
      IP:data.IPv4,
      PublicidadID:nPublicidadID,
      JSON:data
    }
    axios.post(`https://sanluisborder.com:8081/visit`,send);
  }

export default setVisit;
