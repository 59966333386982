import React ,{useEffect,useState} from "react";
import Icon from '@mdi/react';
import axios from "axios";
import { mdiFacebook,mdiWhatsapp,mdiEye } from '@mdi/js';


export const Footer = ()=>{
    const [Count, setCount] = useState(0);

    const getURL = (sURL)=>{
        window.open(sURL,"_blank");
      }

      const Contador= async ()=>{
        const {data} = await axios.get(`https://sanluisborder.com:8081/counter`);
        setCount(data[0].Count);

       
     }

     useEffect(()=>{
        Contador();
      },[]);
      

return(
    <div className="container-foot">
        <div className="container-social">
            <span>Follow us</span>
            <div className="item-social" onClick={()=>getURL('https://www.facebook.com/Sanluisborder')}>
                <Icon path={mdiFacebook}  size={1.5} />
            </div>
            <a className="item-social" href="https://api.whatsapp.com/send?phone=526538497640" target="_blank" rel="noreferrer"><Icon path={mdiWhatsapp}  size={1.5} /></a>
            <span style={{"maxWidth":"60px"}}>¡Contáctanos para publicitarte!</span>
        </div>
    <div className="container-social"><div style={{"backgroundColor": "red","padding":"5px","borderRadius":"2px","display": "inline-flex"}}><Icon path={mdiEye}  size={0.7} style={{marginRight: "1px"}} /><p>{Count}</p></div></div>
    <span>© Copyright 2022. Derechos Reservados ®</span>
    </div>
);
}