import React from "react";
import './../style/desc_ubicacion.css';

function Desc_ubicacion(){
  return (
    <div className="desc_ubicacion">
      <h1>¡<span style={{color:"red"}}>BIENVENIDO</span> A SAN LUIS BORDER!</h1>
      <div><span style={{"fontFamily": "Gotham Light"}}>Garita San Luis Río Colorado, Sonora información en tiempo real </span> ¡En <span style={{color:"red"}}>VIVO</span> 24 Horas!</div>
      {/* <button>Contáctanos</button> */}
    </div>
    
  );

}

export default Desc_ubicacion;
