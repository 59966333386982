import React,{useState,useEffect} from 'react';
import CamStream from './../helpers/app';
import './../style/camera.css';
import {PublicidadTipo} from './advertising';
import { mdiCctv } from '@mdi/js';
import { mdiCarSide } from '@mdi/js';
import Icon from '@mdi/react';

export function Camera_Principal({sourceCam,camName,locationCam,imgList,nPos,locationPoint}){
 
  const [source, setSource] = useState(sourceCam);  
   
  
  useEffect(() => {  
    CamStream(source);
  },[]); 

  return(
    <>
      
      <div className="cam-container">
          <div className='cam-header' >
            <div className='cam-desc-1'>
            <div style={{verticalAlign:'middle',display: "flex"}}><Icon path={mdiCarSide} size={0.6}/><span style={{marginLeft:"3px"}}>{locationCam}</span></div>
            </div>
            <div className='cam-name-desc'>
              <div className='cam-name-street'>
                <div>
                 {locationPoint}
                </div>
              </div>
              <div className='cam-name'><Icon path={mdiCctv} size={0.6}/>{" "+camName}</div>
            </div>
          </div>
          <PublicidadTipo sTipo="LG" nPos={nPos} imgList={imgList}/>
        <div id="remoteVideos" style={{top:"0px",height: "100%"}}>{/* style={{height: "fit-content"} */}
            <video id={"remoteVideo"+source} width="100%" height="100%"   muted  playsInline style={{height: "100%"}} ></video>
        </div>
        {/* </div> */}
      </div>
    </>
  );
}

