import axios from "axios";
import FormData from "form-data";

 const CamStream = async (channel) => {
  let stream = new MediaStream();
  let uuid = "27aec28e-6181-4753-9acd-0456a75f0289";//source;
  const serverURL = `https://sanluisborder.com:8443`;
  // const serverURL = `http://${window.location.hostname}:8083`;
  let videoElem = document.querySelector("#remoteVideo"+channel);
  let config = {
    iceServers: [{
      urls: ["stun:stun.l.google.com:19302"]
    }]
  };
  
  const pc = new RTCPeerConnection(config);
  pc.onnegotiationneeded = handleNegotiationNeededEvent;
  
  let log = msg => {
    //document.getElementById('div').innerHTML += msg + '<br>'
  }
  
  
  const Play_Video = ()=>{
    let options = {
      rootMargin: '0px',
      threshold: 1.0
    }
    
    var ob = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (!entry.isIntersecting) {
          entry.target.pause();
        } else {
         // console.log(entry.target.id);
          entry.target.play();
        }
      });
      
    }, options);

    ob.observe(videoElem);
    // document.querySelectorAll('video').forEach((item) => {
    //   ob.observe(item);
    // });
  }

  pc.ontrack = (event)=> {
    stream.addTrack(event.track); 
    videoElem.srcObject = stream;
    //log(event.streams.length + ' track is delivered')
  }

  pc.oniceconnectionstatechange = e => log(pc.iceConnectionState)
  
  async function handleNegotiationNeededEvent() {
    let offer = await pc.createOffer();
    await pc.setLocalDescription(offer);
    getRemoteSdp();
  }
   
  
  const getCodecInfo = () => {  
    pc.addTransceiver('video', {
      'direction': 'sendrecv'
    });

    Play_Video();
  }

  const getRemoteSdp = () => {
   let url = serverURL+"/stream/" + uuid + "/channel/" + channel + "/webrtc?uuid=" + uuid + '&channel=' + channel;
   //console.log(url);
   const form = new FormData();
   //form.append('suuid', suuid);
   form.append('data', btoa(pc.localDescription.sdp));
   axios.post(url,form)
    .then(response => {
      try {
        pc.setRemoteDescription(new RTCSessionDescription({
          type: 'answer',
          sdp: atob(response.data)
        }))
      } catch (e) {
        console.warn(e);
      }
    });
  }
  
  getCodecInfo();

}

export default CamStream;