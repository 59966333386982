import logo from './../logo.svg';
import './../style/header.css';
import { useRef,useState, useEffect } from 'react';
import Icon from '@mdi/react';
import { mdiClockTimeEightOutline,mdiBookOpenPageVariant,mdiCctv ,mdiPhone } from '@mdi/js';

export function Header(){
  return (
    <div className="header">
      <img src={logo} className="App-logo" alt="logo" />
    </div>
  );

}

export function Navbar({  setshowHome }){
const HideMenu = (bValue)=> {
  document.querySelector("#toggle-1").checked = false;
  setshowHome(bValue);
}
  return (
    <>
      <header >{/*</header>className={(isSticky ? " isSticky" : "")} ref={ref} {...rest}>*/}
        <section>
          <div>
            <input type="checkbox" id="toggle-1"></input>
            <label htmlFor="toggle-1" className="toggle-menu">
              <span></span> 
              <span></span> 
              <span></span>
            </label>
            <nav>
              <ul>
                <li><a href="#" onClick={()=>HideMenu(false)} ><i className="icon-thumbs-up-alt"></i><Icon path={ mdiBookOpenPageVariant}  size={1.3} /> Directorio </a></li>
                <li><a href="#waitTimes" onClick={()=>HideMenu(true)} ><i className="icon-thumbs-up-alt"></i><Icon path={mdiClockTimeEightOutline}  size={1.3} /> Tiempos de espera</a></li>
                <li><a href="#cam1" onClick={()=>HideMenu(true)}><i className="icon-home"></i><Icon path={mdiCctv}  size={1.3} /> Cámara 1</a></li>
                <li><a href="#cam2" onClick={()=>HideMenu(true)}><i className="icon-user"></i><Icon path={mdiCctv}  size={1.3} /> Cámara 2</a></li>
                <li><a href="#cam3" onClick={()=>HideMenu(true)}><i className="icon-user"></i><Icon path={mdiCctv}  size={1.3} /> Cámara 3</a></li>
                <li><a href="#cam4" onClick={()=>HideMenu(true)}><i className="icon-user"></i><Icon path={mdiCctv}  size={1.3} /> Cámara 4</a></li>
                <li><a href="#cam5" onClick={()=>HideMenu(true)}><i className="icon-user"></i><Icon path={mdiCctv}  size={1.3} /> Cámara 5</a></li>
                <li><a href="#cam6" onClick={()=>HideMenu(true)}><i className="icon-user"></i><Icon path={mdiCctv}  size={1.3} /> Cámara 6</a></li>
              </ul>
            </nav>
          </div>
          <div>
            <img src={logo} className="App-logo" onClick={()=>window.location.reload()}/>
            {/* <div className="video-icon">
              <div className="circle-outer"></div>
              <div className="circle-inner"></div>
            </div> */}
          </div>
         <a href="tel:+526538497640" className='contact-opt'>
          {/* <a href="tel:+526531653050" ></a> */}
          <Icon path={mdiPhone}  size={1.5} />
         </a>
        </section>
      </header>
    </>
  );

}

