import React,{useState,useEffect} from "react";
import setVisit from './../helpers/getIP'
import './../style/advertising.css';

export const PublicidadTipo = ({sTipo,nPos=0,imgList})=>{

     const [adList, setadList] = useState(imgList);
 

    useEffect(() => {
      setadList(imgList);
    })
    

    const sComponent = ()=>{
      switch(sTipo){
        case "L":
          
          // console.log(`Large Pos: ${nPos} data: ${adList['RC'][nPos]['Nombre']}`);
          return <Ads_Large sPublicidadID={adList['RC'][nPos]['PublicidadID']} sURL={adList['RC'][nPos]['URL']} imgSrc={adList['RC'][nPos]['Nombre']}></Ads_Large>
        case "G":
        const props = {
          sPublicidadID_L: adList['SQ'][nPos]['PublicidadID'],
          imgSrc_L: adList['SQ'][nPos]['Nombre'],
          sURL_L: adList['SQ'][nPos]['URL'], 
          sPublicidadID_R: adList['SQ'][nPos+1]['PublicidadID'],
          imgSrc_R: adList['SQ'][nPos+1]['Nombre'],
          sURL_R: adList['SQ'][nPos+1]['URL']
        }
        // console.log(`Grid Pos: ${nPos} y ${nPos+1} L: ${adList['SQ'][nPos]['Nombre']} R:${adList['SQ'][nPos+1]['Nombre']}`);
          return <Ads_Grid {...props}></Ads_Grid>
        case "C":
          // console.log(`Card Pos: ${nPos} data:${adList['SQ'][nPos]['Nombre']}`);
          return <Ads_Card sPublicidadID={adList['SQ'][nPos]['PublicidadID']} sURL={adList['SQ'][nPos]['URL']} imgSrc={adList['SQ'][nPos]['Nombre']}></Ads_Card>
        case "LG":
          // console.log(`Card Pos: ${nPos} data:${adList['SQ'][nPos]['Nombre']}`);
          return <Ads_Logos sPublicidadID={adList['LG'][nPos]['PublicidadID']} sURL={adList['LG'][nPos]['URL']} imgSrc={adList['LG'][nPos]['Nombre']}></Ads_Logos>
      }
    }

   return(
   <>
      {sComponent()}
   </>);
    
}

const getURL = (sURL,sPublicidadID)=>{
  console.log('sPublicidadID', sPublicidadID)
  setVisit(sPublicidadID);
  window.open(sURL,"_blank");
}


const Ads_Grid = ({imgSrc_L,sURL_L,imgSrc_R,sURL_R,sPublicidadID_L,sPublicidadID_R})=>{

    return (
      <div className="grid">
        <div>
            <img className='grid-item' src={`/img/${imgSrc_L}`}  onClick={()=>getURL(sURL_L,sPublicidadID_L)}/>
            <img className='grid-item' src={`/img/${imgSrc_R}`}  onClick={()=>getURL(sURL_R,sPublicidadID_R)}/>
        </div>
      </div>
    );
  }
  
const Ads_Card = ({imgSrc,sURL,sPublicidadID})=>{ 
    return (
      <div className="card-container">
          <img src={`/img/${imgSrc}`} className='card-item' onClick={()=>getURL(sURL,sPublicidadID)} />
      </div>
    );
  }

const Ads_Logos = ({imgSrc,sURL,sPublicidadID})=>{ 
    return (
      <>
          <img src={`/img/logos/${imgSrc}`} onClick={()=>getURL(sURL,sPublicidadID)} style={{height: "100%",maxWidth:"100%",width:"100%",cursor: "pointer"}} />
      </>
    );
  }

const Ads_Video = ()=> {
    return(
        <div className='ad-video'>
            <div className='cam_video'>
                
                <iframe 
                width="100%" 
                height="100%" 
                src="https://www.youtube.com/embed/z2H2ww03oDg" 
                title="YouTube video player" 
                frameBorder="0" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                allowFullScreen>
                </iframe>
               
            </div>
        </div>
    );
}

const Ads_Large = ({imgSrc,sURL,sPublicidadID})=> {
  
    return(
        <div  className='ad-large'>
          <div className='ad-large-item'>
              <img src={`/img/${imgSrc}`}  onClick={()=>getURL(sURL,sPublicidadID)}/>         
          </div>
        </div>
    );
}