import React,{useState,useEffect} from "react";
import "./../style/directorio.css";
import Icon from '@mdi/react';
import { mdiFacebook,mdiInstagram } from '@mdi/js';
import axios from "axios";



const CardInfo = ({info})=>{
  const getURL = (sURL)=>{
    if(sURL==""){
      return;
    }
    window.open(sURL,"_blank");
  } 

    const GetSocial = ()=>{
      return(
        <>
        <div className="card-row"><span style={{"fontFamily": "Gotham Black"}}>Redes sociales:</span></div>
        <div className="card-row">{(info.Facebook != '' ) ? <CardSocialItem sIcon={mdiFacebook} link={info.Face_Link} sName={info.Facebook}/>: ''}{(info.Instagram != '' ) ? <CardSocialItem sIcon={mdiInstagram} link={info.Insta_Link} sName={info.Instagram}/>: ''}</div>
        </>
      );
    }
   

    return(
    <>
        <div className="card-tittle"> <img style={{"width":"150px"}} src={`/img/logos/${info.Logo}`}/></div>
        <div className="card-row"><span style={{"fontFamily": "Gotham Black"}} >Teléfono:</span> <a href={(info.Whatsapp_Link == "" ? `tel:${info.Telefono}` : info.Whatsapp_Link )} className="Link-Color" target="_blank" >{info.Telefono}</a></div>
        <div className="card-row" style={{"cursor":"pointer"}} onClick={()=>getURL(info.Maps_Link)}><span style={{"fontFamily": "Gotham Black"}}>Dirección:</span> {info.Direccion}</div>
        <div className="card-row"><span style={{"fontFamily": "Gotham Black"}}>Correo:</span> <a href={`mailto:${info.Correo}`} className="Link-Color">{info.Correo}</a></div>
        {(info.Facebook != '' || info.Instagram != '' ) ? <GetSocial/>: '' }
        <div className="card-row card-line "></div>
        
    </>);
}



const CardSocialItem = ({sIcon,sName,link})=>{
  const getSocialURL = (sURL)=>{
    if(sURL==""){
      return;
    }
    window.open(sURL,"_blank");
  }
  return(
  <div className="card-social" onClick={()=>getSocialURL(link)}>
    <Icon style={{"color": "#E62137","paddingRight":"5px"}} path={sIcon}  size={1.5} /><span>{sName}</span>
  </div>);
}



export const Directorio = ()=> {
  const [jDirectorio, setjDirectorio] = useState([]);

  function AccordionClick(Id) {
    var acc = document.getElementById(Id);
        acc.classList.toggle("active");
        var panel = acc.nextElementSibling;
        if (panel.style.maxHeight) {
          panel.style.maxHeight = null;
        } else {
          panel.style.maxHeight = panel.scrollHeight + "px";
        }
  }

  const ObtenerDirectorio = async ()=>{
    const {data} = await axios.get(`https://sanluisborder.com:8081/directorio`);
    setjDirectorio(data);
 }

  useEffect(() => {
    ObtenerDirectorio();
  }, []);
   
  const CardHeader = jDirectorio.map((data)=>
   <div key={data.CategoriasID}>
      <div id={data.CategoriasID} className="accordion" onClick={()=>AccordionClick(data.CategoriasID)}><img className="item-cat" src={`/img/cat/${data.Icono}`}/>{data.Nombre}</div>
      <div className="panel">
        {data.data.map((info)=>
          <CardInfo key={info.Clientes_DirectorioID} info={info} />  
        )}
               
      </div>
   </div>
);
    return (
      <>
        <h1 style={{"textAlign": "center","margin": "5%"}}>DIRECTORIO</h1>
        {CardHeader}    
      </>
    );
}

