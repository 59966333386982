import React ,{useEffect,useState} from "react";
import ReactDOM from 'react-dom';
import './index.css';
import Desc_ubicacion from './components/desc_ubicacion';
import {Navbar} from './components/header';
import {Camera_Principal} from './components/camera';
import {PublicidadTipo} from './components/advertising';
import Border_Wait from './components/border_wait';
import {Footer} from './components/footer';
import setVisit from './helpers/getIP';
import axios from "axios";
import { Directorio } from "./components/directorio";
// import AOS from 'aos';
// import 'aos/dist/aos.css'; 

//AOS.init();

const Loading = ()=>{
  return(
    <>
    <div className="spinner">
      <div className="double-bounce1"></div>
      <div className="double-bounce2"></div>
    </div>
    </>
  );
}



const Wrap = ({addList})=>{
  const [showHome, setshowHome] = useState(true);
  useEffect(()=>{
    setshowHome(true);
  },[]);
  const CamContent = (
    <>
     <Desc_ubicacion />
        <PublicidadTipo   sTipo="L" nPos={0} imgList={addList}/> 
        <div className="container-column">
          <div className='column'>
            <div id="cam1"></div>
            <Camera_Principal camName={"CÁMARA 1"} sourceCam={"cam63"} locationCam={"CALLE 3"} locationPoint={"VISTA AL OESTE"} nPos={0} imgList={addList} /> 
            <PublicidadTipo sTipo="C" nPos={0} imgList={addList}/>
            <div id="cam2"></div>
            <Camera_Principal  camName={"CÁMARA 2"} sourceCam={"cam64"} locationCam={"CALLE 3"}  locationPoint={"VISTA AL ESTE"} nPos={1} imgList={addList}/>
            <PublicidadTipo sTipo="C" nPos={1} imgList={addList}/>
            <PublicidadTipo sTipo="G" nPos={2} imgList={addList}/>
            <div id="cam3"></div>
            <Camera_Principal  camName={"CÁMARA 3"} sourceCam={"cam49"} locationCam={"CALLE 9"} locationPoint={"VISTA AL OESTE"} nPos={2} imgList={addList}/>
            <PublicidadTipo sTipo="C" nPos={4} imgList={addList}/>
            <PublicidadTipo   sTipo="L" nPos={1} imgList={addList}/> 
            <div id="cam4"></div>
            <Camera_Principal camName={"CÁMARA 4"} sourceCam={"cam28"} locationCam={"CALLE 9"} locationPoint={"VISTA AL ESTE"} nPos={3} imgList={addList}/>
            <PublicidadTipo sTipo="C" nPos={5} imgList={addList}/>
            <PublicidadTipo sTipo="G" nPos={6} imgList={addList}/>
            <div id="cam5"></div>
            <Camera_Principal camName={"CÁMARA 5"} sourceCam={"cam62"} locationCam={"CALLE 13"} locationPoint={"VISTA AL OESTE"} nPos={4} imgList={addList}/>
            <PublicidadTipo sTipo="C" nPos={8} imgList={addList}/>
            <div id="cam6"></div>
            <Camera_Principal camName={"CÁMARA 6"} sourceCam={"cam61"} locationCam={"CALLE 13"} locationPoint={"VISTA AL ESTE"} nPos={5} imgList={addList}/>
            <PublicidadTipo sTipo="C" nPos={9} imgList={addList}/>
          </div>
          <div className='column'>
          </div>
        </div>
        <div id="waitTimes"></div>
        <Border_Wait imgList={addList} />
        <PublicidadTipo sTipo="G" nPos={10} imgList={addList}/>
    </>
  );
  
  return(
      <>
    <Navbar setshowHome={setshowHome}/>
    <div className='container'>
      <div className="container-content">   
       {showHome ? CamContent : <div style={{"paddingTop": "90px"}}><Directorio/></div>}
      </div>
    </div>
    <Footer/>
    </>
  );
}

const Page = ()=>{
  let imgList = {
    "RC": [
        {
            "PublicidadID": "",
            "Nombre": "",
            "URL": ""
        },
        {
          "PublicidadID": "",
            "Nombre": "",
            "URL": ""
        }
    ],
    "SQ": [
        {
          "PublicidadID": "",
            "Nombre": "",
            "URL": ""
        },
        {
          "PublicidadID": "",
            "Nombre": "",
            "URL": ""
        },
        {
          "PublicidadID": "",
            "Nombre": "",
            "URL": ""
        },
        {
          "PublicidadID": "",
            "Nombre": "",
            "URL": ""
        },
        {
          "PublicidadID": "",
            "Nombre": "",
            "URL": ""
        },
        {
          "PublicidadID": "",
            "Nombre": "",
            "URL": ""
        },
        {
          "PublicidadID": "",
            "Nombre": "",
            "URL": ""
        },
        {
          "PublicidadID": "",
            "Nombre": "",
            "URL": "" 
        }
    ],
    LG:[
      {
        "PublicidadID": "",
          "Nombre": "",
          "URL": "" 
      },
      {
        "PublicidadID": "",
          "Nombre": "",
          "URL": "" 
      },
      {
        "PublicidadID": "",
          "Nombre": "",
          "URL": "" 
      },
      {
        "PublicidadID": "",
          "Nombre": "",
          "URL": "" 
      }
    ]
  };
  

  const [addList, setaddList] = useState(imgList);
  const [addLoaded, setLoaded] = useState(false);

  const ObtenerPublicidad = async ()=>{
     const {data} = await axios.get(`https://sanluisborder.com:8081/publicidad`);
    setaddList(data)
    setVisit();
    
  }

  useEffect(()=>{
    ObtenerPublicidad().then(()=>setLoaded(true));
  },[]);
  
  return(<>{addLoaded ? <Wrap addList={addList}/> : <Loading />}</>);
  
}



ReactDOM.render(
  <>
     <Page/>    
  </>,
  document.getElementById('root')
);

