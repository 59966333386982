import React,{useState,useEffect} from "react";
import axios from "axios";
import './../style/border_wait.css';
import logo from './../wait.svg';
import Icon from '@mdi/react';
import { mdiCar,mdiWalk,mdiClockTimeEightOutline  } from '@mdi/js'
import {PublicidadTipo} from './advertising';

function Border_Wait({imgList}){
  
  let time = {
    General: {
      Time: "0",
      Lane: "0",
      Color: "0"
    },
    Sentri: {
      Time: "0",
      Lane: "0",
      Color: "0"
    },
    Ready: {
      Time: "0",
      Lane: "0",
      Color: "0"
    },
      Pedestrian: {
      Time: "0",
      Lane: "0",
      Color: "0"
    }

  };
  
 
  const [WaitTime, setWaitTime] = useState({...time});

  const getWaitTime = async ()=>{

    const response = await axios.get('https://bwt.cbp.gov/api/bwtRss/CSV/-1/51/51');
    const data = await response.data;

    let setTime,setLane;    
    let mySubString = data.substring(
      data.indexOf("<![CDATA[") + 9, 
      data.indexOf("]]></description>")
      );

    let array = mySubString.split("24 hrs/day");

    let General = array[2].substring(
      array[2].indexOf("General Lanes:") + 14, 
      array[2].indexOf("Sentri Lanes:")
      );
      
    setTime = General.substring(
    General.indexOf("MST ") + 4, 
    General.indexOf(" min")
    );

    setLane = General.substring(
      General.indexOf("delay ") + 6, 
      General.indexOf(" lane(s)")
      );

    time.General.Color = getColor(setTime);
    time.General.Time = setTime;
    time.General.Lane = setLane;
    
    
    let Sentri = array[2].substring(
      array[2].indexOf("Sentri Lanes:") + 13, 
      array[2].indexOf(" 	Ready Lanes:")
      );

      setTime = Sentri.substring(
        Sentri.indexOf("MST ") + 4, 
        Sentri.indexOf(" min")
        );

        setLane = Sentri.substring(
          Sentri.indexOf("delay ") + 6, 
          Sentri.indexOf(" lane(s)")
          );

        time.Sentri.Color = getColor(setTime);
        time.Sentri.Time = setTime;
        time.Sentri.Lane = setLane;
    
    let Ready = array[2].substring(
      array[2].indexOf("Ready Lanes:") + 12, 
      array[2].lastIndexOf("")
      );

      setTime = Ready.substring(
        Ready.indexOf("MST ") + 4, 
        Ready.indexOf(" min")
        );

        setLane = Ready.substring(
          Ready.indexOf("delay ") + 6, 
          Ready.indexOf(" lane(s)")
          );

        time.Ready.Color = getColor(setTime);
        time.Ready.Time = setTime;
        time.Ready.Lane = setLane;

    let Pedestrian = array[3].substring(
      array[3].indexOf("General Lanes:") + 14, 
      array[3].indexOf(" 	Ready Lanes:")
      );

      setTime = Pedestrian.substring(
        Pedestrian.indexOf("MST ") + 4, 
        Pedestrian.indexOf(" min")
        );

        setLane = Pedestrian.substring(
          Pedestrian.indexOf("delay ") + 6, 
          Pedestrian.indexOf(" lane(s)")
          );

        time.Pedestrian.Color = getColor(setTime);
        time.Pedestrian.Time = setTime;
        time.Pedestrian.Lane = setLane;
        setWaitTime(time)
  }

  function getColor(setTime){
    let colorGreen = "#0ea83c";
    let colorYellow = "#e8b85b";
    let colorRed = "#ee4545";

    if(parseInt(setTime)<= 10){
      return colorGreen;
    }

    if(parseInt(setTime) <=25){
      return colorYellow;
    }

    if(parseInt(setTime) > 20){
      return colorRed;
    }

  }

  useEffect(()=>{
    getWaitTime()
  },[]);
  
  return (
    <div className="border_wait">
       <img src={logo} className="App-logo" alt="logo" />
      {/* <Ads_Large/> */}
      <PublicidadTipo sTipo="L" nPos={2} imgList={imgList}/>
      <div className="Wait-Container">
        <div className="Border-Notice">
            <span className="center"><Icon path={mdiCar}  size={1} /> Vehículos </span> 
        </div>
        <div className="Wait-item">
          <div className="Wait-Circle" style={{backgroundColor: WaitTime.General.Color }}>
            <Icon path={mdiCar}  size={1} />
          </div>
          <div className="Wait-Desc">
            <span className="Wait-Desc-title">General</span>
            <span className="Wait-Desc-time"> <Icon path={mdiClockTimeEightOutline}  size={0.5} /> {`${WaitTime.General.Time} minutos`}</span>
            <span className="Wait-Desc-lane">{`${WaitTime.General.Lane} linea(s) abierta(s)`}</span>
          </div>
        </div>
        <div className="Wait-item">
          <div className="Wait-Circle" style={{backgroundColor: WaitTime.Ready.Color }}>
            <Icon path={mdiCar}  size={1} />
          </div>
          <div className="Wait-Desc">
            <span className="Wait-Desc-title">Ready</span>
            <span className="Wait-Desc-time"> <Icon path={mdiClockTimeEightOutline}  size={0.5} /> {`${WaitTime.Ready.Time} minutos`}</span>
            <span className="Wait-Desc-lane">{`${WaitTime.Ready.Lane} linea(s) abierta(s)`}</span>
          </div>
        </div>
        
        <div className="Wait-item">
          <div className="Wait-Circle" style={{backgroundColor: WaitTime.Sentri.Color }}>
            <Icon path={mdiCar}  size={1} />
          </div>
          <div className="Wait-Desc">
            <span className="Wait-Desc-title">Sentri</span>
            <span className="Wait-Desc-time"> <Icon path={mdiClockTimeEightOutline}  size={0.5} /> {`${WaitTime.Sentri.Time} minutos`}</span>
            <span className="Wait-Desc-lane">{`${WaitTime.Sentri.Lane} linea(s) abierta(s)`}</span>
          </div>
        </div>
        <div className="Border-Notice">
            <span className="center"><Icon path={mdiWalk }  size={1} /> Peatonal </span> 
        </div>
        <div className="Wait-item">
          <div className="Wait-Circle" style={{backgroundColor: WaitTime.Pedestrian.Color }}>
            <Icon path={mdiWalk }  size={1} />
          </div>
          <div className="Wait-Desc">
            <span className="Wait-Desc-title">General</span>
            <span className="Wait-Desc-time"> <Icon path={mdiClockTimeEightOutline}  size={0.5} /> {`${WaitTime.Pedestrian.Time} minutos`}</span>
            <span className="Wait-Desc-lane">{`${WaitTime.Pedestrian.Lane} linea(s) abierta(s)`}</span>
          </div>
        </div>
      </div>

      {/* <div className="Border-Notice">
      <span className="center"> <Icon path={mdiAlertOutline}  size={1.5} /> A Ready Lane is now open at the San Luis Port of Entry from 6:00 until midnight</span>
        
      </div> */}
      
    </div>
    
  );
}

export default Border_Wait;
